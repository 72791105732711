import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import imgOne from "../../assets/images/gallery/images/images-1.jpg"
import imgOneThumb from "../../assets/images/gallery/images/images-thumb-1.jpg"
import imgTwo from "../../assets/images/gallery/images/images-2.jpg"
import imgTwoThumb from "../../assets/images/gallery/images/images-thumb-2.jpg"
import imgThree from "../../assets/images/gallery/images/images-3.jpg"
import imgThreeThumb from "../../assets/images/gallery/images/images-thumb-3.jpg"
import imgFour from "../../assets/images/gallery/images/images-4.jpg"
import imgFourThumb from "../../assets/images/gallery/images/images-thumb-4.jpg"
import imgFive from "../../assets/images/gallery/images/images-5.jpg"
import imgFiveThumb from "../../assets/images/gallery/images/images-thumb-5.jpg"
import imgSix from "../../assets/images/gallery/images/images-6.jpg"
import imgSixThumb from "../../assets/images/gallery/images/images-thumb-6.jpg"
import imgSeven from "../../assets/images/gallery/images/images-7.jpg"
import imgSevenThumb from "../../assets/images/gallery/images/images-thumb-7.jpg"
import imgEight from "../../assets/images/gallery/images/images-8.jpg"
import imgEightThumb from "../../assets/images/gallery/images/images-thumb-8.jpg"

const imagesPage = () => (
  <Layout>
    <SEO title="Images" />
    <main>
        <div className="standard-container">
          <h2 className="relative uk-text-center mt6 mb5">
             Images
            <div className="text-underline-heavy" style={{marginLeft: "auto", marginRight: "auto"}}></div>
          </h2>
          <div className="uk-grid-small uk-child-width-1-1 uk-child-width-1-4@s uk-text-center" data-uk-grid  data-uk-lightbox="animation: slide">
            <a href={imgOne}  >
              <div className="animated-card animated-card-overlay-trigger">
              <div className="uk-inline w-100">
                  <img src={imgOneThumb} className="w-100" alt=""/>
                  <div className="uk-overlay uk-overlay-primary uk-position-cover animated-card-overlay flex items-center justify-center">
                      <p className="uk-text-bold text-light">
                        Learn More
                      </p>
                  </div>
              </div>
              </div>
            </a>
            <a href={imgTwo}  >
              <div className="animated-card animated-card-overlay-trigger">
              <div className="uk-inline w-100">
                  <img src={imgTwoThumb} className="w-100" alt=""/>
                  <div className="uk-overlay uk-overlay-primary uk-position-cover animated-card-overlay flex items-center justify-center">
                      <p className="uk-text-bold text-light">
                        Learn More
                      </p>
                  </div>
              </div>
              </div>
            </a>
            <a href={imgThree}>  
              <div className="animated-card animated-card-overlay-trigger">
              <div className="uk-inline w-100">
                  <img src={imgThreeThumb} className="w-100" alt=""/>
                  <div className="uk-overlay uk-overlay-primary uk-position-cover animated-card-overlay flex items-center justify-center">
                      <p className="uk-text-bold text-light">
                        Learn More
                      </p>
                  </div>
              </div>
              </div>
            </a>
            <a href={imgFour}  >
              <div className="animated-card animated-card-overlay-trigger">
              <div className="uk-inline w-100">
                  <img src={imgFourThumb} className="w-100" alt=""/>
                  <div className="uk-overlay uk-overlay-primary uk-position-cover animated-card-overlay flex items-center justify-center">
                      <p className="uk-text-bold text-light">
                        Learn More
                      </p>
                  </div>
              </div>
              </div>
            </a>
            <a href={imgFive}  >
              <div className="animated-card animated-card-overlay-trigger">
              <div className="uk-inline w-100">
                  <img src={imgFiveThumb} className="w-100" alt=""/>
                  <div className="uk-overlay uk-overlay-primary uk-position-cover animated-card-overlay flex items-center justify-center">
                      <p className="uk-text-bold text-light">
                        Learn More
                      </p>
                  </div>
              </div>
              </div>
            </a>
            <a href={imgSix}  >
              <div className="animated-card animated-card-overlay-trigger">
              <div className="uk-inline w-100">
                  <img src={imgSixThumb} className="w-100" alt=""/>
                  <div className="uk-overlay uk-overlay-primary uk-position-cover animated-card-overlay flex items-center justify-center">
                      <p className="uk-text-bold text-light">
                        Learn More
                      </p>
                  </div>
              </div>
              </div>
            </a>
            <a href={imgSeven}  >
              <div className="animated-card animated-card-overlay-trigger">
              <div className="uk-inline w-100">
                  <img src={imgSevenThumb} className="w-100" alt=""/>
                  <div className="uk-overlay uk-overlay-primary uk-position-cover animated-card-overlay flex items-center justify-center">
                      <p className="uk-text-bold text-light">
                        Learn More
                      </p>
                  </div>
              </div>
              </div>
            </a>
            <a href={imgEight}  >
              <div className="animated-card animated-card-overlay-trigger">
              <div className="uk-inline w-100">
                  <img src={imgEightThumb} className="w-100" alt=""/>
                  <div className="uk-overlay uk-overlay-primary uk-position-cover animated-card-overlay flex items-center justify-center">
                      <p className="uk-text-bold text-light">
                        Learn More
                      </p>
                  </div>
              </div>
              </div>
            </a>

          </div>
          {/* <div className="mv5">
          <ul className="uk-pagination uk-flex-center uk-light" data-uk-margin>
            <li><a href="#"><span data-uk-pagination-previous></span></a></li>
            <li><a href="#">1</a></li>
            <li className="uk-disabled"><span>...</span></li>
            <li><a href="#">5</a></li>
            <li><a href="#">6</a></li>
            <li className="uk-active"><span>7</span></li>
            <li><a href="#">8</a></li>
            <li><a href="#"><span data-uk-pagination-next></span></a></li>
          </ul>
          </div>    */}
        </div>
        
    </main>
  </Layout>
)

export default imagesPage
